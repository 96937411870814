import axios from 'axios'
import Vue from 'vue'

import Vuex from 'vuex'
import blackopsAppInsights from './plugins/blackopsAppInsights'

import { getEndpoints } from '@/api'
import RouteGuardTrigger from '@/globals/routeGuardTrigger'
// import InitChatBot from '@/globals/chatBot'

// import { initAuth } from './auth'
import App from './App.vue'
import router from './router'
import store from './store'
import './assets/styl/styles.scss'
import VueAnalytics from 'vue-analytics'
import VueClipboard from 'vue-clipboard2'
import PortalVue from 'portal-vue'
import VueApollo from 'vue-apollo'
import apolloClient from './apollo/config'

import { identityService, AuthManager } from '@/features/identity'

import './plugins/vue-input-facade'
import '@/globals/directives'

import vuetify from './plugins/vuetify'
import { ApiEndpointsModel, AuthInitProps } from './features/identity/models'
import { Globals } from './globals/globals'
import { getBannerFeatureFlags } from './features/authorization/apiEndpoints'
import { BannerFeatureFlagName } from './features/authorization/constants'
import { bannerFeatureProvider } from './features/authorization/bannerFeatureProvider'
import { bannerProvider, getBanners } from './features/banners'
// @ts-ignore
import GmapVue from 'gmap-vue'

require('./globals/filters')

Vue.config.productionTip = false
Vue.config.performance = true

Vue.use(Vuex)
Vue.use(VueAnalytics, {
  id: 'UA-120093607-1',
  router
})
Vue.use(GmapVue, {
  installComponents: true,
  load: {
    key: process.env.VUE_APP_GOOGLE_MAPS_TOKEN,
    map_ids: process.env.VUE_APP_GOOGLE_MAPS_ID
  }
})
Vue.use(VueClipboard)
// Vue.directive(formRequired)
// Vue.use(vueInputFacade)
Vue.use(RouteGuardTrigger)
Vue.use(PortalVue)
Vue.use(VueApollo)

axios.defaults.baseURL = process.env.VUE_APP_API_BASE_URL

// miniprofiler
function axiosSetUp (): void {
  const miniProfiler: any = (window as any).MiniProfiler
  axios.interceptors.response.use(function success (config) {
    if (!!miniProfiler && !!config.headers['x-miniprofiler-ids']) {
      const miniProfilerIds = JSON.parse(config.headers['x-miniprofiler-ids']) as string[]
      miniProfiler.fetchResults(miniProfilerIds)
    }
    return config
  }, function bug (error) {
    return Promise.reject(error)
  })
}
axiosSetUp()

//  ==============================================================================================================
//                                ⚠️⚠️ IMPORTANT NOTE/GOTCHA ⚠️⚠️
//  API Calls are NOT guaranteed to include an authorization header if executed in the below boot/setup.
//  The Authorization header is only added after identity has finished setup, which cannot be done synchronously.
//  This setup is not guaranteed to be completed before the Vue app is initialized.
//
//  If you need API calls to execute before the  app becomes usable after user load
//  you must register lifecycle hooks with the IdentityService. (registerLifecycleHook() and registerAsyncLifecycleHook())
//  These will execute ina  blocking manner, and the initial app state will wait until these callbacks complete.
//  ==============================================================================================================

// Async startup self-executing function
// Gets API endpoints data and initializes app

// eslint-disable-next-line no-void
void (async () => {
  const endpoints: ApiEndpointsModel = (await getEndpoints()).data

  // Register feature flags for fill in after user loads
  identityService.registerAsyncLifecycleHook('AfterInitialized', async () => {
    const bannerFeatureFlags = await getBannerFeatureFlags()
    bannerFeatureProvider.init(bannerFeatureFlags.data)
  })

  // Register banner retrieval before we process viewas info (As view as relies on banner data)
  identityService.registerAsyncLifecycleHook('InitializingViewAs', async () => {
    const bannerDetails = await getBanners()
    bannerProvider.init(bannerDetails.data)
  })

  // Initialize AuthManager & IdentityService
  // IdentityService is the provider for the auth manager
  const authManager = new AuthManager(new AuthInitProps(axios, endpoints))
  await identityService.init(authManager)

  const { identityAuthority, appInsightsInstrumentationKey, environment, storefrontAdminApi } = endpoints

  // setup apollo
  const apolloProvider = new VueApollo({
    defaultClient: apolloClient(storefrontAdminApi)
  })

  if (appInsightsInstrumentationKey) {
    Vue.use(blackopsAppInsights, {
      id: appInsightsInstrumentationKey,
      roleName: 'sales',
      router,
      store
    })
  }

  Globals.environment = environment
  Vue.prototype.$environment = environment

  // We define this function as a wrapper to ensure reactivity, we do NOT want an arrow function here
  Vue.prototype.$hasFeature = function (feature: BannerFeatureFlagName) {
    // KEEP THIS: Kludge to force reactivity to the user object, doing this registers watchers/
    // We don't pass this in to the call because Vuex rewrites our objects losing everything on the prototype,
    //   and we care about the prototype on our user object.
    const user = this.$store.getters['user/user']
    const debugMode = this.$store.getters.debug

    return bannerFeatureProvider.hasBannerFeature(feature, debugMode)
  }

  // Mount app
  new Vue({
    data: {},
    router,
    store,
    apolloProvider,
    vuetify,
    render: (h) => h(App)
  }).$mount('#app')

  store.dispatch('setApolloProvider', apolloProvider)

  // InitChatBot() init chatbot widget leave uncommented until we want to show it
})()
