const SET_STOREFRONT_ROLES = (state, roles) => {
  state.roles = roles
}

const SET_STOREFRONT_ENTITY_ID = (state, id) => {
  state.entityId = id
}

const SET_STOREFRONT_PARENT_CUSTOMER = (state, parent) => {
  state.webUserParent = parent
}

const SET_REX_GEX_PIM_ENTITY_ID = (state, id) => {
  state.rexGexPimEntityId = id
}

const SET_REX_GEX_IMPERSONATION_URL = (state, rexGexPimImpersonationUrl) => {
  state.rexGexPimImpersonationUrl = rexGexPimImpersonationUrl
}

const SET_STOREFRONT_USERS = (state, users) => {
  state.users = users
}

const SET_IMPERSONATION_URL = (state, url) => {
  state.impersonationUrl = url
}

const SET_STOREFRONT_CUSTOMER_KEY = (state, key) => {
  state.customerKey = key
}

const SET_STOREFRONT_CUSTOMER_PARENT_KEY = (state, key) => {
  state.parentKey = key
}

const UPDATE_STORE_FRONT_USER_STATUS_BY_INDEX = (state, { index, status }) => {
  state.users[index].status = status
}

const DELETE_STORE_FRONT_USER_BY_INDEX = (state, index) => {
  state.users.splice(index, 1)
}

const SET_PIMS_BY_CUSTOMER_ID = (state, pims) => {
  state.pims = pims
}

const ADD_NEW_PIM = (state, pimData) => {
  state.pims.push(pimData)
}

const ADD_NEW_PIM_ID = (state, pimId) => {
  state.newPimId = pimId
}

const REMOVE_PIM_BY_ID = (state, pimId) => {
  const pimIdInt = parseInt(pimId)
  const index = state.pims.findIndex(pim => parseInt(pim.cimId) === pimIdInt)
  if (index < 0) {
    return
  }
  state.pims.splice(index, 1)
}

const MIGRATE_PIMS_BY_CUSTOMER_ID = (state, newPims) => {
  state.newMigratedPims = newPims
}

const SET_PIM_HAS_BEEN_MIGRATED = (state, value) => {
  state.pimsAreMigrated = value
}

const SET_NEW_PIMS_LOADED = (state, value) => {
  state.newPimsLoaded = value
}

const SET_STOREFRONT_MANAGEDINVENTORY_PROGRAMS = (state, programs) => {
  state.managedInventoryPrograms = programs
}

const SET_STOREFRONT_ANCESTOR_KEYS = (state, keys) => {
  state.ancestorKeys = keys
}

const UPDATE_EXISTING_PIM_NAME_AND_TYPE = (state, data) => {
  const { cimId, cimName, cimTypeId } = data
  const index = state.pims.findIndex(p => p.cimId === cimId)
  state.pims[index].cimName = cimName
  state.pims[index].cimTypeId = cimTypeId
}

export default {
  SET_STOREFRONT_ROLES,
  SET_STOREFRONT_ENTITY_ID,
  SET_STOREFRONT_PARENT_CUSTOMER,
  SET_REX_GEX_PIM_ENTITY_ID,
  SET_REX_GEX_IMPERSONATION_URL,
  SET_STOREFRONT_USERS,
  SET_STOREFRONT_ANCESTOR_KEYS,
  SET_STOREFRONT_MANAGEDINVENTORY_PROGRAMS,
  SET_IMPERSONATION_URL,
  SET_STOREFRONT_CUSTOMER_KEY,
  UPDATE_STORE_FRONT_USER_STATUS_BY_INDEX,
  DELETE_STORE_FRONT_USER_BY_INDEX,
  SET_PIMS_BY_CUSTOMER_ID,
  ADD_NEW_PIM,
  ADD_NEW_PIM_ID,
  REMOVE_PIM_BY_ID,
  MIGRATE_PIMS_BY_CUSTOMER_ID,
  SET_PIM_HAS_BEEN_MIGRATED,
  SET_NEW_PIMS_LOADED,
  UPDATE_EXISTING_PIM_NAME_AND_TYPE,
  SET_STOREFRONT_CUSTOMER_PARENT_KEY
}
