import SalesGetEclipseBillTo from '@/gql/SalesGetEclipseBillTo.gql'
import SalesGetEclipseEnterprise from '@/gql/SalesGetEclipseEnterprise.gql'
import SalesGetPlattCustomer from '@/gql/SalesGetPlattCustomer.gql'
import SalesGetPlattEnterprise from '@/gql/SalesGetPlattEnterprise.gql'
import GetCustomerRolesByBannerCode from '@/store/modules/storefrontUsers/gql/GetCustomerRolesByBannerAccountNumber.gql'
import PimListByCustomerId from '@/gql/pim/PimListByCustomerId.gql'
import PimMigrate from '@/gql/pim/PimMigrate.gql'
import PimHasBeenMigrated from '@/gql/pim/PimHasBeenMigrated.gql'
import PimCreate from '@/gql/pim/PimCreate.gql'
import PimDelete from '@/gql/pim/PimDelete.gql'
import PimInventoryCopyFromId from '@/gql/pim/PimInventoryCopyFromId.gql'
import PimInventoryUpload from '@/gql/pim/PimInventoryUpload.gql'
import PimInventoryCopyFromStock from '@/gql/pim/PimInventoryCopyFromStock.gql'
import PimUpdateNameAndType from '@/gql/pim/PimUpdateNameAndType.gql'
import CustomerByErpBannerAccountNumber from '@/gql/CustomerByErpBannerAccountNumber.gql'
import { getCustomerObjectByName } from '@/globals/utils'
import store from '@/store'
import { bannerProvider } from '@/features/banners'
import gql from 'graphql-tag'

const sortRolesByAdmin = (roles) => {
  return roles.sort((a, b) => {
    const aCompare = a.name === 'Admin' ? -1 : 1
    const bCompare = b.name === 'Admin' ? -1 : 1
    return aCompare - bCompare
  })
}

const fetchCustomerManagedInventoryPrograms = async ({ commit }, customerKeys) => {
  try {
    const responses = customerKeys.map(key => {
      return doGraphQlRequest(
        gql`
          query ManagedInventoryPrograms($customerKey: EntityKey!) {
            managedInventory {
              programs(customerKey: $customerKey) {
                __typename
                id
              }
            }
          }
        `,
        { customerKey: key }
      )
    })

    const programs = []
    const results = await Promise.all(responses)
    results.forEach((res) => {
      if (res.data.managedInventory.programs) {
        programs.push(...res.data.managedInventory.programs)
      }
    })

    commit(
      'SET_STOREFRONT_MANAGEDINVENTORY_PROGRAMS',
      programs
    )
  } catch (error) {
    store.commit('setToastMessage', {
      message: 'Failed to fetch managed inventory programs.',
      status: 'error'
    })
  }
}

const setStorefrontRolesVuex = async ({ commit, state, rootState, rootGetters }) => {
  const { accountId } = rootState.currentCustomer.customer
  const bannerCode = rootGetters['currentCustomer/customerBanner']

  try {
    const response = await doGraphQlRequest(GetCustomerRolesByBannerCode, { bannerAccountNumber: accountId, bannerCode: bannerCode.toUpperCase() })
    const {
      data: {
        admin: {
          customerByErpBannerAccountNumber: {
            roles
          }
        }
      }
    } = response

    if (roles) {
      const sortedRoles = sortRolesByAdmin(roles)
      commit('SET_STOREFRONT_ROLES', sortedRoles)
    }
  } catch (error) {
    console.error(error)
    store.commit('setToastMessage', {
      message: 'Unable to retrieve Storefront Roles',
      status: 'error'
    })
  }
}

const setStoreFrontUserData = async ({ commit, dispatch, rootState }) => {
  /*
    For Platt, if customer.isEnterprise is true, pull enterprise users; otherwise, pull normal users
    For Rexel, if customer.associatedAccounts.length > 0 then pull enterprise users; otherwise, pull bill-to users
    For Rexel, if the enterprise query fails then try again with the bill-to query
    The check for customer.associatedAccounts.length is inspired by the logic in ParentAccountBanner.vue
  */

  const customer = rootState.currentCustomer.customer

  const {
    accountId,
    billTo,
    billToCompanyCustomerNumber,
    companyId,
    isEnterprise,
    jobAccount,
    shipTo
  } = customer

  const isPlatt = bannerProvider.isPlatt(companyId)

  /*
    isAccountParentOfItself was added to satisfy the criteria for #38557. Per Jeff Harris, the
    accounts where the eclipseParentId is the same as the eclipseBillToId are considered Enterprise.
    TODO: the better way to do this is to implement the IsEnterprise and EnterpriseNumber fields
    from proc_CustomerGet for Rexel and Gexpro customers (which is in place for Platt already).
    Aaron is able to do this on the database side, although it is a breaking change for Sales Central
    as some of the code assumes isEnterprise is Platt-only. The concept of 'enterprise' in Sales Central
    is also different than in Storefront, so that needs to be reconciled as well as tech-debt cleanup.
  */
  const isAccountParentOfItself = customer.accountId === customer.parentCustomerNumber

  const sfBannerCode = bannerProvider.tryGetStorefrontBannerCode(companyId)
  if (!sfBannerCode) return

  /* Reset state */
  commit('SET_STOREFRONT_USERS', [])
  commit('SET_STOREFRONT_ENTITY_ID', null)
  commit('SET_REX_GEX_PIM_ENTITY_ID', null)
  commit('SET_STOREFRONT_PARENT_CUSTOMER', null)
  commit('SET_IMPERSONATION_URL', '')
  dispatch('resetNewPims')

  /* Determine the relevant graphQl query to use */
  const query = isPlatt
    ? isEnterprise
      ? SalesGetPlattEnterprise
      : SalesGetPlattCustomer
    : (isEnterprise || isAccountParentOfItself)
        ? SalesGetEclipseEnterprise
        : billTo
          ? SalesGetEclipseBillTo
          : CustomerByErpBannerAccountNumber

  if (query === null) {
    return
  }

  /* ***
   * billToCompanyCustomerNumber are Reg/Gex data properties. If that value is null (as it will be for Platt),
   * we should use accountId
   * ***/
  const customerNumber = !isPlatt && billTo ? billToCompanyCustomerNumber : accountId

  let admin = null
  try {
    const variables = { customerNumber, bannerCode: sfBannerCode }
    const response = await doGraphQlRequest(query, variables)
    admin = response.data.admin
    if (query === SalesGetEclipseEnterprise && (!admin || response.errors?.length)) {
      const retryResponse = await doGraphQlRequest(billTo ? SalesGetEclipseBillTo : CustomerByErpBannerAccountNumber, variables)
      admin = retryResponse.data.admin
    }
  } catch (e) { admin = null }

  if (!admin) {
    console.error('Unable to retrieve Storefront Web Accounts data')
    setToastError(commit, 'Unable to retrieve Web Accounts')
    return
  }

  const mainObject = admin.customerByErpBannerAccountNumber ||
    admin.customerGroupByEclipseParentNumber ||
    admin.customerGroupByEclipseBillToNumber ||
    admin.customerGroupByAs400EnterpriseNumber

  if (mainObject) {
    const parent = mainObject?.parent
    if (parent) {
      commit('SET_STOREFRONT_PARENT_CUSTOMER', parent)
    }

    const roles = mainObject?.customers?.nodes?.[0]?.roles || mainObject?.roles
    if (roles) {
      const sortedRoles = sortRolesByAdmin(roles)
      commit('SET_STOREFRONT_ROLES', sortedRoles)
    } else {
      await dispatch('setStorefrontRolesVuex')
    }

    let formattedUsers = null

    if (!isPlatt && billTo && shipTo && mainObject.customers?.nodes[0]?.linksAll?.nodes /* select customers linked at the shipTo level for Eclipse billTo/shipTo if available */) {
      formattedUsers = await formatUsers(mainObject.customers.nodes[0].linksAll.nodes, customerNumber)
    } else if (mainObject.linksAll?.nodes) {
      formattedUsers = await formatUsers(mainObject.linksAll.nodes, accountId)
    }

    if (formattedUsers) commit('SET_STOREFRONT_USERS', formattedUsers)

    const isRexGexPimEnabled = store.getters['storefrontUsers/isRexGexPimEnabled']

    // Rex/Gex PIMs can exist only on shipTo customers that are not jobAccounts
    const rexGexPimQuery = isRexGexPimEnabled && shipTo && !jobAccount
      ? CustomerByErpBannerAccountNumber
      : null

    if (rexGexPimQuery) {
      const variables = { customerNumber, bannerCode: sfBannerCode }
      const response = await doGraphQlRequest(rexGexPimQuery, variables)
        .catch(e => console.error(e))
      const admin = response?.data?.admin

      if (!admin) {
        console.error('Unable to retrieve Storefront Web Accounts data for Rex/Gex PIMs')
        setToastError(commit, 'Unable to retrieve Web Accounts')
        return
      }

      const mainObject =
        admin.customerGroupByEclipseParentNumber ||
        admin.customerByErpBannerAccountNumber ||
        admin.customerGroupByEclipseBillToNumber

      if (mainObject?.id) {
        commit('SET_REX_GEX_PIM_ENTITY_ID', mainObject.id)
      }
      if (mainObject?.impersonationUrl) {
        commit('SET_REX_GEX_IMPERSONATION_URL', mainObject.impersonationUrl)
      }
    }

    if (mainObject.id) {
      commit('SET_STOREFRONT_ENTITY_ID', mainObject.id)
      if (isPlatt || isRexGexPimEnabled) {
        dispatch('initNewPims')
      }
    }

    if (mainObject.impersonationUrl) {
      commit('SET_IMPERSONATION_URL', mainObject.impersonationUrl)
    }

    const customerKeys = []
    if (mainObject.key) {
      customerKeys.push(mainObject.key)
      commit('SET_STOREFRONT_CUSTOMER_KEY', mainObject.key)
    }

    const topLevelParentKey = mainObject.parent?.parent?.key || mainObject.parent?.key
    if (topLevelParentKey) {
      customerKeys.push(topLevelParentKey)
      commit('SET_STOREFRONT_CUSTOMER_PARENT_KEY', topLevelParentKey)
    }

    if (mainObject.ancestorKeys) {
      customerKeys.push(...mainObject.ancestorKeys)
      commit('SET_STOREFRONT_ANCESTOR_KEYS', mainObject.ancestorKeys)
    }

    const uniqueCustomerKeys = [...new Set(customerKeys)]
    dispatch('fetchCustomerManagedInventoryPrograms', uniqueCustomerKeys)
  }
}

const updateStoreFrontUserStatusByWebUserId = ({ commit, state }, { webUserId, status }) => {
  const { users } = state
  const index = users.findIndex((user) => user.webUserId === webUserId)
  if (index < 0) {
    console.error(`No user with webUserId: ${webUserId} could be found`)
    return
  }
  if (status.toLowerCase() !== 'deleted') {
    commit('UPDATE_STORE_FRONT_USER_STATUS_BY_INDEX', { index, status })
    return
  }
  commit('DELETE_STORE_FRONT_USER_BY_INDEX', index)
}

/* ****************
 * StorefrontPIMs
 * ****************/

const initNewPims = async ({ commit, dispatch }) => {
  await dispatch('setPimHasBeenMigrated')
  await dispatch('setPimsByCustomerId')

  commit('SET_NEW_PIMS_LOADED', true)
}

const resetNewPims = ({ commit }) => {
  commit('SET_PIMS_BY_CUSTOMER_ID', [])
  commit('SET_NEW_PIMS_LOADED', false)
  commit('SET_PIM_HAS_BEEN_MIGRATED', false)
}

const setPimHasBeenMigrated = async ({ commit, rootState, rootGetters }) => {
  const customerBanner = rootGetters['currentCustomer/customerBanner']
  const sfBannerCode = bannerProvider.getStorefrontBannerCode(customerBanner)

  const { accountId } = rootState.currentCustomer.customer

  doGraphQlRequest(PimHasBeenMigrated, { bannerCode: sfBannerCode, customerNumber: accountId })
    .then(response => {
      const {
        data: {
          admin: {
            customerByErpBannerAccountNumber
          }
        }
      } = response
      const hasMigratedCimSolutions = customerByErpBannerAccountNumber?.hasMigratedCimSolutions ?? false
      commit('SET_PIM_HAS_BEEN_MIGRATED', hasMigratedCimSolutions)
    })
    .catch(error => {
      console.error(error)
    })
}

const setPimsByCustomerId = async ({ commit, getters, state }) => {
  const entityId = getters.entityIdForPim
  if (!entityId) {
    return
  }

  doGraphQlRequest(PimListByCustomerId, { customerId: entityId })
    .then(response => {
      const {
        data: {
          admin: {
            customerById
          }
        }
      } = response
      const nodes = customerById?.cimSolutions?.nodes ?? []
      const pims = nodes.map(n => {
        const { __typename, ...properties } = n
        return properties
      })
      commit('SET_PIMS_BY_CUSTOMER_ID', pims)
    }).catch(error => {
      console.error(error)
      const isEnterprise = store.state.currentCustomer?.customer?.isEnterprise
      const isPlatt = bannerProvider.isPlatt(store.state.currentCustomer?.customer?.companyId)
      if (!(isPlatt && isEnterprise)) {
        setToastError(commit, 'Unable to retrieve New Pims')
      }
    })
}

const migratePimsByCustomerId = async ({ commit, getters, state }) => {
  /* TODO: When PIM is available for all Rex/Gex customers, go back to getting entityId from state  */
  // const { entityId } = state

  const entityId = getters.entityIdForPim

  doGraphQlRequest(PimMigrate, { customerId: entityId })
    .then(response => {
      const {
        data: {
          cimSolutionMigrateLegacy: {
            data
          }
        }
      } = response
      commit('SET_PIMS_BY_CUSTOMER_ID', data)
      commit('SET_NEW_PIMS_LOADED', true)
      commit('SET_PIM_HAS_BEEN_MIGRATED', true)
    }).catch(error => {
      console.error(error)
      setToastError(commit, 'Unable to migrate PIMs')
    })
}

const createPim = async ({ commit, getters, state }, cimSolutionCreateInput) => {
  /* TODO: When PIM is available for all Rex/Gex customers, go back to getting entityId from state  */
  // const { entityId } = state
  const entityId = getters.entityIdForPim

  const cimTypeId = getCimTypeId(cimSolutionCreateInput.cimTypeId)

  if (!cimTypeId) {
    console.error('Bad cimType id')
    setToastError(commit, 'Bad Pim Type')
    return
  }

  cimSolutionCreateInput.cimTypeId = cimTypeId

  const variables = {
    customerId: entityId,
    input: cimSolutionCreateInput
  }

  return new Promise(async (resolve, reject) => {
    await doGraphQlRequest(PimCreate, variables)
      .then(response => {
        const errors = collectResponseErrors(response)

        if (errors.length > 0) {
          console.error(errors)
          reject(new Error('Unable to create PIM'))
          return
        }
        const {
          data: {
            cimSolutionCreate: {
              data,
              userErrors
            }
          }
        } = response
        if (userErrors.length > 0) {
          const errorMessage = userErrors[0].message
          reject(errorMessage)
          return
        }
        const { __typename, ...pimData } = data
        commit('ADD_NEW_PIM', pimData)
        commit('ADD_NEW_PIM_ID', pimData.cimId)
        resolve(pimData)
      })
      .catch(error => {
        console.error(error)
        setToastError(commit, 'Unable to add PIM')
        reject(error)
      })
  })
}

const deleteNewPim = async ({ commit, getters, state }, targetPimId) => {
  /* TODO: When PIM is available for all Rex/Gex customers, go back to getting entityId from state  */
  // const { entityId } = state
  const entityId = getters.entityIdForPim

  const variables = {
    customerId: entityId,
    cimId: targetPimId
  }

  return new Promise(async (resolve, reject) => {
    await doGraphQlRequest(PimDelete, variables)
      .then((response) => {
        const errors = collectResponseErrors(response)

        if (errors.length > 0) {
          console.error(errors)
          reject(new Error('Unable to delete PIM'))
          return
        }

        const {
          data: {
            cimSolutionDelete: {
              userErrors
            }
          }
        } = response

        if (userErrors.length > 0) {
          const errorMessage = userErrors[0].message
          reject(errorMessage)
          return
        }
        commit('REMOVE_PIM_BY_ID', targetPimId)
        resolve(true)
      })
      .catch(error => {
        console.error(error)
        setToastError(commit, `Unable to remove PIM ${targetPimId}`)
      })
  })
}

const copyPimInventoryFromPim = async ({ commit, getters, state, rootState }, payload) => {
  /* TODO: When PIM is available for all Rex/Gex customers, go back to getting entityId from state  */
  // const { entityId } = state
  const { targetPimId, copyFromPimId, labelRequestData } = payload
  const entityId = getters.entityIdForPim

  const cimSolutionInventoryCopyPayload = {
    cimId: targetPimId,
    cimIdsToCopyFrom: [copyFromPimId],
    customerId: entityId,
    labelRequestInput: buildCimInventoryLabelRequestInput(labelRequestData),
    quantityUpdateMethod: 'REPLACE',
    targetQuantityUpdateMethod: 'REPLACE'
  }

  return new Promise(async (resolve, reject) => {
    doGraphQlRequest(PimInventoryCopyFromId, cimSolutionInventoryCopyPayload)
      .then(response => {
        const errors = collectResponseErrors(response)

        if (errors.length > 0) {
          console.error(errors)
          reject(new Error('Unable to copy PIM data'))
          return
        }

        const {
          data: {
            cimSolutionInventoryCopyFromCim: {
              userErrors
            }
          }
        } = response
        if (userErrors.length > 0) {
          const errorMessage = userErrors[0].message
          reject(errorMessage)
          return
        }
        resolve(true)
      })
      .catch(error => {
        console.error(error)
        reject(error)
      })
  })
}

const copyPimInventoryFromStock = async ({ commit, getters, state }, payload) => {
  /* TODO: When PIM is available for all Rex/Gex customers, go back to getting entityId from state  */
  // const { entityId } = state
  const entityId = getters.entityIdForPim
  const { invoiceNumber, quoteId, targetPimId, labelRequestData } = payload

  const invoiceNumbers = invoiceNumber != null ? [invoiceNumber] : []
  const quoteIds = quoteId != null ? [parseInt(quoteId)] : []

  const cimSolutionStockInventoryFromPayload = {
    cimId: targetPimId,
    customerId: entityId,
    invoiceNumbers,
    labelRequestInput: buildCimInventoryLabelRequestInput(labelRequestData),
    quantityUpdateMethod: 'REPLACE',
    quoteIds,
    targetQuantityUpdateMethod: 'REPLACE'
  }

  return new Promise(async (resolve, reject) => {
    doGraphQlRequest(PimInventoryCopyFromStock, cimSolutionStockInventoryFromPayload)
      .then((response) => {
        const {
          data: {
            cimSolutionStockInventoryFrom: {
              userErrors
            }
          }
        } = response
        if (userErrors.length > 0) {
          const errorMessage = userErrors[0].message
          reject(errorMessage)
          return
        }
        resolve(true)
      })
      .catch(error => {
        reject(error)
      })
  })
}

const uploadPimInventory = async ({ commit, getters, state }, payload) => {
  /* TODO: When PIM is available for all Rex/Gex customers, go back to getting entityId from state  */
  // const { entityId } = state
  const entityId = getters.entityIdForPim
  const { fileBase64, targetPimId, labelRequestData } = payload

  const labelRequest = buildCimInventoryLabelRequestInput(labelRequestData)

  const cimSolutionInventoryUploadPayload = {
    base64FileUri: fileBase64,
    cimId: targetPimId,
    customerId: entityId,
    fileExtension: 'csv',
    labelRequestInput: labelRequest,
    quantityUpdateMethod: 'REPLACE',
    targetQuantityUpdateMethod: 'REPLACE'
  }

  return new Promise(async (resolve, reject) => {
    doGraphQlRequest(PimInventoryUpload, cimSolutionInventoryUploadPayload)
      .then(response => {
        const {
          data: {
            cimSolutionInventoryUpload: {
              userErrors
            }
          }
        } = response
        if (userErrors.length > 0) {
          reject(userErrors[0].message)
          return
        }
        resolve(true)
      })
      .catch(error => {
        console.error(error)
        reject(error)
      })
  })
}

const updateExistingPimNameAndType = async ({ commit, getters, state }, data) => {
  /* TODO: When PIM is available for all Rex/Gex customers, go back to getting entityId from state  */
  // const { entityId } = state
  const entityId = getters.entityIdForPim
  const { pimId, pimName, pimTypeId } = data
  const cimTypeId = getCimTypeId(pimTypeId)

  const input = {
    cimId: pimId,
    cimName: pimName,
    cimTypeId
  }

  const variables = {
    customerId: entityId,
    input
  }

  return new Promise(async (resolve, reject) => {
    doGraphQlRequest(PimUpdateNameAndType, variables)
      .then(response => {
        const {
          data: {
            cimSolutionPatch: {
              userErrors
            }
          }
        } = response
        if (userErrors.length > 0) {
          reject(userErrors[0].message)
          return
        }
        commit('UPDATE_EXISTING_PIM_NAME_AND_TYPE', input)
        resolve(true)
      })
      .catch(error => {
        console.error(error)
        reject(error)
      })
  })
}

/* ****************
 * Helper Functions
 * ****************/

const formatUsers = (nodes, customerAccountId) => {
  const formattedUsers = nodes.filter(u => u.entity !== null && u.webUser !== null).map(u => {
    const { __typename, as400EnterpriseNumber, ...entityData } = u.entity

    return {
      allowedCimIds: u.allowedCimIds || [],
      created: u.createdUtc,
      email: u.webUser.email,
      entity: entityData,
      firstName: u.webUser.profile.firstName,
      lastName: u.webUser.profile.lastName,
      lastActiveUtc: u.lastActiveUtc,
      legacyAccountId: u.legacyAccountID_ZNode,
      phoneNumber: u.webUser.profile.phoneNumber === null ? '' : u.webUser.profile.phoneNumber,
      roles: u.roles,
      rolesDisplay: setRolesDisplay(u.roles),
      status: u.consentCustomer.status,
      webUserId: u.webUser.id,
      linkedErpNumber: null
    }
  })

  return new Promise(async resolve => {
    await formattedUsers.map(async (u, index) => {
      try {
        const customerObj = await getCustomerObjectByName(u.entity.displayNameWithErpNumbers, customerAccountId)
        if (!customerObj) {
          return
        }
        formattedUsers[index].linkedErpNumber = customerObj.customerId
      } catch (e) {
        console.error(e)
      }
    })
    resolve(formattedUsers)
  })
}

const buildCimInventoryLabelRequestInput = (data) => {
  if (!data) {
    return null
  }
  return {
    bannerBranchNumber: parseInt(data.labelsBranch),
    numberOfCopies: data.copies,
    recipient: data.labelsTo,
    requestAllProducts: data.labelsForItems === 'all',
    requestDate: data.requestDate,
    productNumbers: []
  }
}

const setRolesDisplay = (userRoles) => {
  const stateRoles = store.state.storefrontUsers.roles

  return userRoles.map((userRole) => {
    const stateRoleObject = stateRoles.find(stateRole => stateRole.role === userRole)
    return stateRoleObject !== undefined ? stateRoleObject.name : ''
  }).sort().join(', ').toUpperCase()
}

const setToastError = (commit, message) => {
  commit(
    'setToastMessage',
    { message, status: 'error' },
    { root: true }
  )
}

const doGraphQlRequest = (query, variables) => {
  const apolloClient = store.state.apolloProvider.defaultClient
  return apolloClient.query({
    query,
    variables,
    fetchPolicy: 'no-cache',
    errorPolicy: 'all'
  })
}

const getCimTypeId = (cimTypeValue) => {
  const cimTypeId = Number.isInteger(cimTypeValue) ? cimTypeValue : parseInt(cimTypeValue)
  switch (cimTypeId) {
    case 1:
      return 'VAN'
    case 2:
      return 'SHOP'
    case 3:
      return 'JOBSITE'
    default:
      return null
  }
}

const collectResponseErrors = (response) => {
  return response.errors ? response.errors : []
}

export default {
  setStoreFrontUserData,
  setStorefrontRolesVuex,
  fetchCustomerManagedInventoryPrograms,
  updateStoreFrontUserStatusByWebUserId,
  setPimsByCustomerId,
  setPimHasBeenMigrated,
  migratePimsByCustomerId,
  initNewPims,
  resetNewPims,
  createPim,
  deleteNewPim,
  copyPimInventoryFromPim,
  copyPimInventoryFromStock,
  uploadPimInventory,
  updateExistingPimNameAndType
}
