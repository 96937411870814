import { ApolloLink, concat } from 'apollo-link'
import { BatchHttpLink } from 'apollo-link-batch-http'
import { InMemoryCache, IntrospectionFragmentMatcher } from 'apollo-cache-inmemory'
import introspectionQueryResultData from '@/apollo/fragmentTypes.json'
import { ApolloClient } from 'apollo-client'
import store from '../store/index'

const authMiddleware = new ApolloLink((operation, forward) => {
  operation.setContext({
    headers: {
      authorization: `Bearer ${store.state.user.accessToken}`,
      EmployeeSso: store.state.user.user.sso
    }
  })
  return forward(operation)
})

export default (uri) => {
  return new ApolloClient({
    link: concat(
      authMiddleware,
      new BatchHttpLink({
        uri
      })
    ),
    cache: new InMemoryCache({
      fragmentMatcher: new IntrospectionFragmentMatcher({
        introspectionQueryResultData
      }),
      dataIdFromObject: object => object.id
    }),
    connectToDevTools: true,
    defaultHttpLink: false
  })
}
